import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
const MainContainer = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 64px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    margin: 18px 0;
  }

  Link {
    word-break: keep-all;
  }

  h1,
  h2,
  h3 {
    width: 100%;
    text-align: center;
  }
`

const JobTitle = styled.h1`
  width: 100%;
  text-align: center;
`

function JobPost({ data }) {
  const { job } = data
  return (
    <MainContainer
      dangerouslySetInnerHTML={{ __html: job.nodes[0].data.body.html }}
    ></MainContainer>
  )
}

export default JobPost

export const query = graphql`
  query JobQuery($uid: String!) {
    job: allPrismicJob(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          body {
            html
          }
          date(formatString: "MMMM D, Y")
          location
          position
          title
        }
      }
    }
  }
`
